<template>
  <div class="Attract">
    <div class="dl_title">
      <img src="../assets/img/zcfont.png" class="fontimg" />
    </div>
    <div class="zc_box">
      <div class="zc_img">
        <img class="img_lxmf" src="../assets/img/zcimg.png" />
      </div>
      <div class="title_name">
        <div class="top_name">
          <div class="name_1"></div>
          <div class="name_2">姓名:</div>
        </div>
        <input
          class="input_box"
          placeholder="请输入真实姓名"
          v-model="form.name"
        />
        <div class="top_name">
          <div class="name_1"></div>
          <div class="name_2">电话:</div>
        </div>
        <input
          class="input_box"
          placeholder="请输入联系电话"
          v-model="form.mobile"
        />
        <div class="zhu">注：稍后会有高级招商经理与您联系</div>
      </div>
      <div class="zc_btn" @click="submit">提交</div>
    </div>
    <div class="zc_xx">
      <div class="xx_bottom">加盟热线：4008-515-258</div>
      <div>浙江省杭州市滨江区浦沿街道中控科技园F裙楼409</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  created() {
    var query = this.$route.query;
    console.log("参数=", query);
  },
  methods: {
    submit() {
      var telStr =
        /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
      if (!this.form.name) {
        alert("请您输入姓名");
        return;
      }
      if (!telStr.test(this.form.mobile)) {
        alert("手机号码输入不规范");
        return;
      }
      this.$http.joinApply(this.form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          alert("提交成功！");
          this.form = {};
        } else {
          alert("提交失败！请稍后再试");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Attract {
  width: 100%;
  min-height: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  background: url("../assets/img/zsbg.png") no-repeat;
  background-size: cover;
  /* justify-content: space-between; */
  .dl_title {
    margin-top: 24px;
    width: 331px;
    height: 93px;
    .fontimg {
      width: 100%;
      height: 100%;
    }
  }
  .zc_box {
    width: 335px;
    margin-top: 56px;
    background: #ffffff;
    box-shadow: 0px 0px 24px 0px #085ac1;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .zc_img {
      width: 100%;
      height: 76px;
      .img_lxmf {
        width: 100%;
        height: 100%;
      }
    }
    .title_name {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top_name {
        width: 100%;
        height: 22px;
        display: flex;
        margin-bottom: 16px;
        .name_1 {
          margin-left: 16px;
          width: 4px;
          height: 14px;
          background: #0070fb;
          border-radius: 2px;
        }
        .name_2 {
          font-size: 16px;
          font-weight: 500;
          color: #222222;
          margin-left: 8px;
        }
      }
      .input_box {
        font-size: 14px;
        width: 303px;
        height: 48px;
        background: #f3f7fa;
        border-radius: 8px;
        padding-left: 16px;
        box-sizing: border-box;
        margin-bottom: 24px;
      }
      .zhu {
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 400;
        color: #cecece;
        margin-bottom: 28px;
      }
    }
    .zc_btn {
      width: 303px;
      height: 48px;
      background: #0070fb;
      border-radius: 24px;
      margin-bottom: 32px;
      text-align: center;
      font-weight: 500;
      color: #ffffff;
      font-size: 18px;
      line-height: 48px;
    }
  }
  .zc_xx {
    width: 375px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 45px;
    .xx_bottom {
      margin-bottom: 10px;
    }
  }
}
</style>